#mouse {
  /*questa parte è di posizionamento, non dovrebbe servirti*/
/*
  position:relative;
  margin:40px auto;
*/
  /*fine del posizionamento*/
  
  border:2px solid black;
  border-radius: 25px;
  width:40px;
  height:60px;
  transform: rotate(0deg);
/*
  animation-name:mice;
  animation-duration:3.6s;
  animation-iteration-count:infinite;
*/
}

/*
@keyframes mice {
  0% {transform: rotate(-10deg);}
  50%{transform: rotate(10deg);}
  100%{transform: rotate(-10deg);}
}
*/

#mouse div {
  border-radius:3px;
  background-color:#000;
  margin-top:15px;
  margin-left:auto;
  margin-right:auto;
  width:6px;
  height:6px;
  animation-name:wheel;
  animation-duration:1.8s;
  animation-iteration-count: infinite;
}

@keyframes wheel{
  0%   {margin-top:5px; height:6px;}
  80% {margin-top:25px; height:6px; width:6px;}
  90% {height:10px; width:4px;}
  100% {margin-top:5px; height:6px; width:6px;}
}

