.animato {
  animation:backanim 6s linear infinite;
  
  background: #000000;
  background: linear-gradient(to bottom, #000000 33%,#ff4100 33%,#ff4100 66%,#000000 66%);
  background-size: 100% 350%;
}

@keyframes backanim{
  0% {background-position:0% 0%;}
  94% {background-position:0% 0%;}
  96% {background-position:50% 50%;}
  100%{background-position:100% 100%;}
}

.animato a {
  color: var(--redColor);
}

.animato:hover, .animato2:hover{
  animation:backanim-hover 2s linear infinite;
}

@keyframes backanim-hover{
  0% {background-position:0% 0%;}
  12% {background-position:50% 50%;}
  25% {background-position:100% 100%;}
  100% {background-position:100% 100%;}
}

.animato2{
  animation:backanim 6s linear infinite;
  
  background: #000000;
  background: linear-gradient(135deg, #000000 33%,#ff4100 33%,#ff4100 66%,#000000 66%);
  background-size: 350% 350%;
}

.botto{
  position:absolute;
  width:27px;
  height:27px;
  bottom:-14.5px;
  left: calc(50% - 14.5px);
  text-align:center;
  font-weight: 100;
  vertical-align:middle;
  font-size:14px;
  color:#fff;
  
}

.apre{
  transform:rotate(45deg);
  border:1px solid #ff4100;
}

.apre span {
  position:relative;
  display: inline-block;
  transform:rotate(-45deg);
  font-size:23px;
  margin-top: -1px;
  color:#ff4100;
}

.chiude {
  transform:rotate(45deg);
  background:#ff4100;
  color:#fff;
  border: solid 1px transparent;
}

.chiude span{
  display: inline-block;
  transform:rotate(0deg);
  font-size:24px;
  margin-right: -4px;
}

.delay{
  animation-delay:0.4s;
}