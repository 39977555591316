/* -------- facce --------- */

.super-title {
  align-content: center;
  text-align: center;
  position: relative;
  
  & #trump {
    position: absolute;
    width: 23%;
    left: -20%;
    display: block;
    
    @media (--smart) {
    position:relative;
    height:35vh;
    width: auto;
    left:0;
    }

  }
  
  & #putin {
    position: absolute;
    width: 23%;
    right: -20%;
    display: block;
    
    @media (--smart) {
    position:relative;
    height:35vh;
    width: auto;
    left:0;
    }
   
  }
}

#facce {
    
  @media (--smart){
    margin-top:20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
}


/* -------- linea --------- */

hr {
  width: 1px;
  background-color: black;
  opacity: .7;  
  border-width: 0px;
  /*box-shadow: 0.5px 0 0.1px 0.5px black;*/
  margin-top: 0;
  margin-bottom: 0;
}

.line1 {
  height: 20px;
}

.line2 {
  height: 70px;
}

.line3 {
  height: 120px;
}

.line4 {
  width: 40px;
  height: 1px;
  margin-bottom: 10px;
}

.line4B {
  width: 40px;
  height: 1px;
  margin-top: 10px;
}


.line5 {
  width: 100px;
  height: 1px;
  margin-bottom: 60px;
}


.line6 {
  width: 300px;
  height: 1px;
  margin-bottom: 60px;
  background: var(--colorRed);
}


/* -------- container --------- */

.main_column {
  width: 60%;
  margin: 0 20%;
  float: left;
  position: relative;
  
  @media (--smart) {
    width: 80%;
    margin: 0 10%;
  }

  @media (--largeScreen) {
    width: 780px;
    margin: 0 calc(50% - 390px);
  }
}

.black_container {
  background-color: black;
  width: 50%;
  float: left;
  padding:40px 25% 40px 25%;

  & .text {
      color: white;
  }
  
  @media (--sm) {
    width: 70%;
    padding:40px 15%;
  }
  
  @media (--smart) {
    width: 80%;
    padding:40px 10%;
  }

  @media (--largeScreen) {
    width: 640px;
    padding: 40px calc(50% - 320px);
  }
  
}

.timeline {
  width: 100%;
  padding:40px 0 40px 0;
}


.team-nav {
  margin: 70px -20% 20px -20%;
  
  & ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
  
  & li {
    margin: 5px;
    width: 25%;
  }

  @media (--smart) {
    margin: 70px 0% 20px 0%;

    & li {
      width: 70%;
    }
  }
}

/* -------- content --------- */


#powered{
  margin-top: 30px;
  display:flex;
  flex-wrap: unwrap;
  justify-content: center;


  @media (--small) {
    font-size: 0.9em;
    margin-top: 30px; 
    display:flex;
    flex-direction: column;
    justify-content: center;
    
    & .powered_two {
      display: none;
    }
    
    & .powered_three {
      margin-top: 0px;
    }
  }
  
  & .powered_two {
      margin: 16px 10px;
    }
}

#bottoni_timeline {
  width: 60%;
  margin: 0 10%;
  padding: 0 10%;
}

#subtitle {
  width: 50%;
  margin: 30px 25% 50px 25%;
  font-size: 1.1em;
  line-height: 1.6em;
  
  @media (--sm) {
    width: 60%;
    margin: 30px 20% 50px 20%;
  }
  
  @media (--smart) {
    width: 90%;
    margin: 30px 5% 50px 5%;
    font-size: 1em;
    line-height: 1.3em;
  }
}

.protagonisti {
  @media (--smart){
    display: none;
  }
}

.text {
  text-align: center;
  line-height: 1.7em;
  padding: 0 5% 0 5%;
}

.highlight_red {
  color: var(--colorRed);
}

.highlight_white {
  color: white;
}

.bold {
  font-weight: 700;
}

.text_detail {
  width: 70%;
  margin: 50px 15% 50px 15%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 10px 0;
  font-style: italic;
  line-height: 1.6em;
  text-align: justify;
  hyphens: auto;
  letter-spacing: 0.03em;
  
  @media (--smart){
    width: 80%;
    margin: 20px 10% 20px 10%;
    text-align: left;
    hyphens: none;
    letter-spacing: 0em;
  }
  
  & .elenco {
  margin: 15px 0 0 5%;
}
}

#credits {
  margin-top:80px;
  
}

.finale {
  width: 220px;
  margin:0 calc(50% - 110px) 50px;
}


#team_title{
  display:flex;
  flex-wrap: unwrap;
  justify-content: center;
  color: white;
  


  @media (--smart) {
    width:70%;
    margin: 0 15%;
    display:flex;
    flex-direction: column;
    justify-content: center;

  }
}

.crediti {
  margin-top: 50px;
  margin-bottom: 90px;
  font-size: 0.8em;
}

.carousel-cell{
  color: white;
  line-height: 25px;
  
  @media (--smart){
    line-height: 20px;
  }
}

.TVA {
  font-weight: 700;
}

/* -------- callout --------- */

.callout {
  font-family: 'Roboto Mono', sans-serif;
  font-size: 0.9em;
  color: white;
  line-height: 25px;
  letter-spacing: 0.1em;
  width: 60%;
  position: absolute;
  
  & p {
    display: inline;
    padding: 0 10px;
    background-color: black;
  }
  
  @media (--smart){
    line-height: 1.5em;
    width: 70%;
  }
}

.obama {
  text-align: right; 
  top:100px;
  right:0px;
  
  @media (--smart) {
    top:60px;
  }
  
}

.debbie {
  text-align: left;
  top:350px;
  left:0;
  
  @media (--sm) {
    top:330px;
    width:80%;
  }
  
  @media (--smart) {
    top:190px;
    width:80%;
  }
  
  @media (--micro) {
    top:150px;
    width:90%;
  }
}

.hillary {
  text-align: right; 
  top:100px;
  right:0px;
  
  @media (--smart) {
    top:170px;
  }
  
  @media (--micro) {
    top:180px;
    width:100%;
  }
  
}

.mccain {
  text-align: right; 
  top:340px;
  right:0px;
  width: 70%;
  
  @media (--smart) {
    top:160px;
    width: 90%;
  }
  
  @media (--micro) {
    top:150px;
    width:100%;
  }
  
}


/* -------- img --------- */

.mouse-container {
  transform: scale(0.7);
  
  @media (--smart){
    display:none;
  }
}

#mouse {
  margin-bottom: 20px;
  display: inline-block;
}

.img_timeline {
  width: 100%;

  @media (--largeScreen) {
    width: 1200px;
    margin: 0 calc(50% - 600px);
  }
}

.usa {
  display: block;
}

.russia {
  display: none;
}

.personaggio {
   position: relative;
  
  & .img_hat{
    width: 80%;
    margin:80px 10% 60px 10%;
    
    @media (--smart){
      margin:40px 10% 0 10%;
    }
  }
  
  & .img_obama{
    width: 70%;
    margin:80px 20% 60px 10%;
    
    @media (--smart){
      margin:40px 20% 0 10%;
    }
  }
    
  & .img_debbie{
    width: 90%;
    margin:80px 5% 0 5%;  
  
    @media (--sm){
      margin:40px 5% 60px 5%;
    }
    
    @media (--smart){
      margin:40px 5% 80px 5%;
    }
  }
  
  & .img_hillary{
    width: 80%;
    margin:80px 15% 80px 5%;
    
    @media (--smart){
      margin:40px 15% 80px 5%;
    }
  }
  
  & .img_mccain{
    width: 90%;
    margin:80px 5% 100px 5%;
   
    @media (--sm){
      margin:80px 5% 200px 5%;
    }
    
    @media (--smart){
      margin:30px 5% 80px 5%;
    }
    
    @media (--micro){
      margin:30px 5% 120px 5%;
    }
    
  }
}

.img_carousel {
  width: 30%;
  margin: 30px 35%;
  display: inline-block;
}

.ditone {
  width: 20%;
  margin: 0 40% 30px 40%;
  
  @media (--smart){
    width: 30%;
    margin: -20px 35% 30px 35%;
  }
}

/* -------- accordion --------- */

.accordion {
  background-color: black;
  height: 50px;
  padding:25px 5% 0px 5%;
  position: relative;
  margin-top: 20px;
  
  & .title {
    color: var(--colorRed);
    line-height: 1.2em; 
  }
}

.spazio_doppio {
  @media (--smart){
    height: 70px;
  }
}

.accordion-container {
  position: relative;
  margin-bottom: 50px;

  & .botto {
    opacity: 0;
    cursor: pointer;
    transition: opacity .5s ease;
    
  }

  & .is-visible {
    opacity: 1;
    z-index: 1;
  }
}

.accordion_open {
  overflow-y: hidden;
  display: none;

  background-color: black;
  height: auto;
  padding:0 5%;
  position: relative;
  
  & .title {
    color: var(--colorRed);
    line-height: 1.2em; 
  }
  
  & .text {
    color: white;
    font-style: italic;
    text-align: left;
    font-size: 0.9em;
    line-height: 1.9em;
    padding-bottom: 25px;
    letter-spacing: 0.05em;
    
    @media (--smart){
      letter-spacing: 0em;
    }
  }
}


/* -------- bottoni --------- */

.bottone{
  font-size: 0.9em;
  color: var(--colorRed);
  text-transform: uppercase;
  text-align: center; 
  background-color: black;
  width: calc(30% - 4px);
  display: inline-block;
  height: 25px;
  line-height: 25px;  
  letter-spacing: 0.1em;
  border: 1px solid var(--colorRed);
  cursor: pointer;
}

.sx {
  margin:10px calc(2.5% - 4px) 10px calc(17.5% - 4px);
}

.dx {
  margin:10px calc(17.5% - 4px) 10px calc(2.5% - 4px);
}


.selected {
  font-size: 0.9em;
  color: black;
  text-transform: uppercase;
  text-align: center; 
  background-color: var(--colorRed);
  width: calc(30% - 4px);
  display: inline-block;
  height: 25px;
  line-height: 25px;  
  letter-spacing: 0.1em;
  border: 1px solid var(--colorRed);
}

/* -------- timeline --------- */

.main_column-wrapper {
  position: relative;
  float: left;
  width: 100%;
}

.spy-timeline {
  position: absolute;
  left: 1%;
  top: 2rem;
  min-width: 200px;
}

.spy-timeline__item {
  display: block;
  color: #000;
  font-size: 0px;
  height: 20px;
  transition: all .25s;

  cursor: pointer;

  &::before {
    background-color: #000;
    content: '';
    display: inline-block;

    line-height: 16px;
    height: 1px;
    width: 20px;

    margin: 4px 20px 4px 0;
  }

  &:hover {
    font-size: 10px;
    color: #FFF;

    &::before {
      background-color: #fff;
    }
  }

  @media (--smart){
    display: none;
  }
}

.spy-timeline__item.is-active {
  font-size: 12px;
  color: #FFF;

  &::before {
    background-color: #FFF;
  }
}

.spy-timeline__item_empty {
  font-size: 0px;
  cursor: none;
  pointer-events: none;

  &::before {
    width: 7px;
    margin: 4px 30px 4px 0;
    background-color: rgba(0,0,0,.3);
  }
}


