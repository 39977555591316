.carousel-cell {
  width: 100%; /* full width */
  margin-right: 10px;
}

.flickity-viewport {
    transition: height .5s ease;
}

.flickity-prev-next-button {
  display: none;
}

.flickity-page-dots {
  display: none;
}

.team-nav{
  & ul {
    list-style-type: none;
  }

  & li {
    cursor: pointer;
  }
}