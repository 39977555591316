@import 'normalize.css'; 
@import './lib/base.css';

@import 'flickity/css/flickity.css';

@import './components/home.css';
@import './components/mouse.css';
@import './components/bottone.css';
@import './components/carousel.css';

@import url('https://fonts.googleapis.com/css?family=Rubik:400,900');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:100,400,400i,700&subset=latin-ext');


body {
/*  float: left;*/
  width: 100%;
  overflow-x: hidden;
  font-family: 'Roboto Mono', sans-serif;
  font-size: 1em;
  letter-spacing: 0.1em;
  background-color: var(--colorRed);
  
  @media (--smart){
     font-size: 0.8em;
  }

  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

#app, #home {
  width: 100%;
}

a,
a:visited,
a:hover,
a:active {
  color: #000;
}

a:hover {
  color: #fff;
}

h1 {
  font-family: 'Rubik', sans-serif;
  font-size: 6em;
  text-align: center; 
  color: black;
  font-weight: 900;


  @media (--medium) {
    font-size: 5em;
  }
  
  @media (--sm) {
    font-size: 4em;
  }
  
  @media (--small) {
    font-size: 3em;
  }

}

h2 {
  font-family: 'Roboto Mono', sans-serif;
  font-size: 0.9em;
  color: var(--colorRed);
  text-transform: uppercase;
  text-align: center; 
  background-color: black;
  width: 170px;
  height: 25px;
  line-height: 25px;
  margin:0 calc(50% - 85px);
  letter-spacing: 0.1em;
  

}

h3 {
  font-family: 'Roboto Mono', sans-serif;
  font-size: 0.9em;
  color: var(--colorRed);
  text-transform: uppercase;
  text-align: center; 
  background-color: black;
  width: 170px;
  height: 25px;
  line-height: 25px;
  margin:0 calc(50% - 86.5px);
  letter-spacing: 0.1em;
  outline: 1px solid black;
  outline-offset: -1px;
  border: solid 3px var(--colorRed);
}

h4 {
  font-family: 'Roboto Mono', sans-serif;
  font-size: 0.9em;
  color: black;
  text-transform: uppercase;
  text-align: center; 
  background-color: var(--colorRed);
  width: 120px;
  height: 25px;
  line-height: 25px;
  margin:0 calc(50% - 60px);
  letter-spacing: 0.1em;
  border: 1px solid black;
}


h5 {
  font-family: 'Roboto Mono', sans-serif;
  font-size: 0.9em;
  color: black;
  text-transform: uppercase;
  text-align: center; 
  background-color: var(--colorRed);
  height: 25px;
  line-height: 25px;
  letter-spacing: 0.1em;
  margin-top:0px;
  margin-bottom: 5px;
  padding:0 4px;
}

header {
  float: left;
  width: 100%;
  height: 50px;
  position: relative;

  background-color: #000;

  & .logo-corriere {
    position: absolute;
    height: 35%;
    top: 37.5%;
    left: 15px;
  }

  & .social {
    position: absolute;
    width: 110px;
    right: 15px;
    height: 35%;
    top: 37.5%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

footer {
  float: left;
  width: 100%;
  height: 150px;
  position: relative;

  background-color: #000;

  & .logo-corriere {
    position: absolute;
    width: 228.219px;
    top: 30px;
    left: calc(50% - 114.1095px);
  }

  & .social {
    position: absolute;
    width: 110px;
    right: calc(50% - 55px);
    top: 75px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}